import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { clearMultipleData, fetchData } from '@/store/slices/fetch-slice';
import { getData, getStatus } from '@/store/slices/helpers/helpers';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetProductByCategory() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getProductByCategoryData = 
    getData(state, apiKeys.productListByCategory, []);
  const getProductByCategoryStatus = 
    getStatus(state, apiKeys.productListByCategory, '');
  const getProductByCategoryLoading = apiStatuses.pending === getProductByCategoryStatus;
  
  const getProductByCategory = useCallback(
    (inputId) => {
      dispatch(
        fetchData({
          url: apiUrls.productListByCategory(inputId),
          method: apiMethods.GET,
          key: apiKeys.productListByCategory,
        })
      );
    },
    [ dispatch]
  );

  const getProductByCategoryOptions = useMemo(() => {
    return getProductByCategoryData
    .map(({ faProdCatId, productName }) => ({
      id: faProdCatId,
      label: productName
      
    }))
  }, [getProductByCategoryData]);


  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: apiKeys.productListByCategory }));
    },
    [ dispatch]
  );

  return useMemo(
    () => ({
      getProductByCategoryData,
      getProductByCategoryStatus,
      getProductByCategory,
      getProductByCategoryOptions,
      getProductByCategoryLoading
    }),

    [
      getProductByCategoryData,
      getProductByCategoryStatus,
      getProductByCategory,
      getProductByCategoryOptions,
      getProductByCategoryLoading
    ]
  );
}

export default useGetProductByCategory;
