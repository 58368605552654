import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';

// project import
import MainCard from '@/from-theme/components/MainCard';
import IconButton from '@/from-theme/components/@extended/IconButton';
import Transitions from '@/from-theme/components/@extended/Transitions';
import { ThemeMode } from 'config';

// assets
import { BellOutlined, CheckCircleOutlined, GiftOutlined, MessageOutlined, SettingOutlined }
 from '@ant-design/icons';
import useNotification from './Hooks/useNotification';

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem'
};

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none'
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================ //

const Notification = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const {
    open,
    count,
    setCount,
    anchorRef,
    handleToggle,
    handleClose,
    handlePlanApprovalIfoods,
    handlePlanApprovalfoods,
    handleClaimApproval,
    notificationData
  } = useNotification();
  const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ?
   'background.default' : 'grey.100';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        color="secondary"
        variant="light"
        sx={{ color: 'white', bgcolor: open ? iconBackColorOpen : 'transparent' }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge badgeContent={count} >
          <BellOutlined />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="grow" position={matchesXs ? 'top' : 'top-right'} in={open}
           {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: '100%',
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 285
                }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title="Notification"
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <>
                      {count > 0 && (
                        <Tooltip title="Mark as all read">
                          <IconButton color="success" size="small" onClick={() => setCount(0)}>
                            <CheckCircleOutlined style={{ fontSize: '1.15rem' }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  }
                >
                  <List
                    component="nav"
                    sx={{
                      p: 0,
                      '& .MuiListItemButton-root': {
                        py: 0.5,
                        '&.Mui-selected': { bgcolor: 'grey.50', color: 'text.primary' },
                        '& .MuiAvatar-root': avatarSX,
                        '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                      }
                    }}
                  >
                    {notificationData?.planApprovalCount > 0 && (
                      <>
                        <ListItemButton onClick={handlePlanApprovalIfoods} selected={count > 0}>
                          <ListItemAvatar>
                            <Avatar
                              sx={{
                                color: 'success.main',
                                bgcolor: 'success.lighter'
                              }}
                            >
                              <GiftOutlined />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography variant="h6">
                                You Have{' '}
                                <Typography component="span" variant="subtitle1">
                                  {notificationData.planApprovalCount}
                                </Typography>{' '}
                                Pending Plan Approvals
                              </Typography>
                            }
                            secondary="2 min ago"
                          />
                          <ListItemSecondaryAction>
                            <Typography variant="caption" noWrap>
                              3:00 AM
                            </Typography>
                          </ListItemSecondaryAction>
                        </ListItemButton>
                        <Divider />
                      </>
                    )}

                    {notificationData?.planDeviationCount > 0 && (
                      <>
                        <ListItemButton onClick={handlePlanApprovalfoods}>
                          <ListItemAvatar>
                            <Avatar
                              sx={{
                                color: 'primary.main',
                                bgcolor: 'primary.lighter'
                              }}
                            >
                              <MessageOutlined />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography variant="h6">
                                You Have{' '}
                                <Typography component="span" variant="subtitle1">
                                {notificationData.planDeviationCount}
                                </Typography>{' '}
                                Pending Plan Deviation Approvals
                              </Typography>
                            }
                            secondary="2 min ago"
                          />
                          <ListItemSecondaryAction>
                            <Typography variant="caption" noWrap>
                              6:00 PM
                            </Typography>
                          </ListItemSecondaryAction>
                        </ListItemButton>
                        <Divider />
                      </>
                    )}
                    {notificationData?.claimCount > 0 && (
                      <>
                    <ListItemButton onClick={handleClaimApproval} selected={count > 0}>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            color: 'error.main',
                            bgcolor: 'error.lighter'
                          }}
                        >
                          <SettingOutlined />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography variant="h6">
                            You Have{' '}
                            <Typography component="span" variant="subtitle1">
                            {notificationData.claimCount}
                            </Typography>{' '}
                            Pending Claim Approvals
                          </Typography>
                        }
                        secondary="2 min ago"
                      />
                      <ListItemSecondaryAction>
                        <Typography variant="caption" noWrap>
                          2:45 PM
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItemButton>
                    <Divider />
                    </>
                    )}
                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Notification;
