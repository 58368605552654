import { BookOutlined, UserOutlined } from '@ant-design/icons';
import Zone from '../components/zone/zone';
import Subzone from '../components/subzone/subzone';
import VerifyAccess from '@/common/components/verify-access/verify-access';
import { requestedFeaturesAll, requestedMenuAll } from '@/from-theme/routes/constants/menuAccess';
const { zoneTabMasterKey, subZoneTabMasterKey } = requestedMenuAll;
const { readKey } = requestedFeaturesAll;

// tabName is locale id
export const zoneMasterTabs = [
  {
    id: 'zone',
    tabName: 'zone',
    icon: <UserOutlined />,
    tabContent: (
      <VerifyAccess requestedMenu={zoneTabMasterKey} requestedFeature={readKey}>
        <Zone />
      </VerifyAccess>
    )
  },
  {
    id: 'subzone',
    tabName: 'subzone',
    icon: <BookOutlined />,
    tabContent: (
        <VerifyAccess requestedMenu={subZoneTabMasterKey} requestedFeature={readKey}>
          <Subzone />
        </VerifyAccess>
      )
  }
];
