
export const initialValues = {
  primeCusSapCode: "",
  primeCusName: null,
  sapPrimeCusCategory: null,
  primeCusGstNo: null,
  address: null,
  landmark: null,
  zoneId: undefined,
  subzoneId: null,
  contactPerson: null,
  contactNumber: null,
  partCode: null,
  salesSupportRep: null,
  sapTeamLeader: null,
  teamLeaderName: null,
  zoneHeadName: null,
  salesHeadName: null,
  sapZoneHead: null,
  sapSalesHead: null,
  sapZoneName: null,
  sapSubzoneName: null,
  sapDistrictName: null,
  sapPriceGroupName: null,
  sapCityName: null,
  primeCusEmail: null,
  contactWhatsappNo: null,
  salesOrg: null,
  priceGroup: null,
  pincode: null,
  incoterms: null,
  primeCustCategory: undefined,
  sapCountry: null,
  sapCompany: null,
  regionCode: null,
  contactNo: null,
  file: null,
  districtId: null,
  sapTeamLeaderName: null,
  sapSalesSupportRep: null,
  sapSalesHeadName: null,
  sapZoneHeadName: null,
  latitude: null,
  longitude: null,
  uploadFile: null,
  cityId: null,
  sapStateName: '',
  primeCusCategory: undefined,
  activeStatus: 1,


}


 