import { UserOutlined } from '@ant-design/icons';
import Settings from '../components/notification-settings/settings';
import VerifyAccess from '@/common/components/verify-access/verify-access';
import { requestedFeaturesAll, requestedMenuAll } from '@/from-theme/routes/constants/menuAccess';
const { notificationSettingsTabKey } = requestedMenuAll;
const { readKey } = requestedFeaturesAll;

// tabName is locale id
export const settingsTabs = [
  {
    id: 'notification-settings',
    tabName: 'notification-settings',
    icon: <UserOutlined />,
    tabContent: (
      <VerifyAccess requestedMenu={notificationSettingsTabKey} requestedFeature={readKey}>
        <Settings />
      </VerifyAccess>
    )
  },
];
