import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiStatuses } from 'store/api/constants/index';
import fetchApi from 'store/api/fetch-api';

const initialState = {};

const actionTypes = {
  fetchData: 'DATA/FETCH',
  updateData: 'DATA/UPDATE',
  clearData: 'DATA/CLEAR',
  clearMultipleData: 'DATA/CLEARMULTIPLE'
};

export const fetchData = createAsyncThunk(actionTypes.fetchData, async (params, thunkAPI) => {
  let result;
  try {
    const data = await fetchApi({ ...params, signal: thunkAPI.signal });
    if (data.status === 200 || data.status === 204 || data.data?.status === true) {
      result = thunkAPI.fulfillWithValue(data.data);
    } else {
      result = thunkAPI.rejectWithValue(data.error);
    }
  } catch (error) {
    result = thunkAPI.rejectWithValue(error.response.data);
  }
  return result;
});

export const updateData = createAction(actionTypes.updateData, ({ key, data }) => ({
  payload: {
    key,
    data
  }
}));

export const clearData = createAction(actionTypes.clearData, ({ key }) => ({
  payload: {
    key
  }
}));


export const clearMultipleData = createAction(actionTypes.clearMultipleData, ({ key }) => ({
  payload: {
    key
  }
}));

const fetchSlice = createSlice({
  name: 'fetch',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state, action) => {
        state[action.meta.arg.key] = {
          status: apiStatuses.pending,
          requestId: action.meta.requestId
        };
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state[action.meta.arg.key] = {
          status: apiStatuses.succeeded,
          requestId: action.meta.requestId,
          data: action.payload
        };
      })
      .addCase(fetchData.rejected, (state, action) => {
        state[action.meta.arg.key] = {
          status: apiStatuses.rejected,
          requestId: action.meta.requestId,
          error: action.payload
        };
      })
      .addCase(updateData, (state, action) => {
        state[action.payload.key] = {
          data: action.payload.data
        };
      })
      .addCase(clearData, (state, action) => {
        delete state[action.payload.key];
      })
      .addCase(clearMultipleData, (state, action) => {
        action.payload.key
        for(const key of action.payload.key) {
          delete state[key]
        }
      })
      .addDefaultCase(() => {});
  }
});

export default fetchSlice.reducer;
