import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { clearMultipleData, fetchData } from '@/store/slices/fetch-slice';
import { getData, getStatus } from '@/store/slices/helpers/helpers';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetCategoryByBrand() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getCategoiesByBrandData = 
    getData(state, apiKeys.categoryListByBrand, []);
  const getCategoiesByBrandStatus = 
    getStatus(state, apiKeys.categoryListByBrand, '');
  const getCategoiesByBrandLoading = apiStatuses.pending === getCategoiesByBrandStatus;
  
  const getCategoiesByBrand = useCallback(
    (inputId) => {
      dispatch(
        fetchData({
          url: apiUrls.categoryListByBrand(inputId),
          method: apiMethods.GET,
          key: apiKeys.categoryListByBrand,
        })
      );
    },
    [ dispatch]
  );

  const getCategoiesByBrandOptions = useMemo(() => {
    return getCategoiesByBrandData
    .map(({ fdCatId, categoryName }) => ({
      id: fdCatId,
      label: categoryName
      
    }))
  }, [getCategoiesByBrandData]);


  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: apiKeys.categoryListByBrand }));
    },
    [ dispatch]
  );

  return useMemo(
    () => ({
      getCategoiesByBrandData,
      getCategoiesByBrandStatus,
      getCategoiesByBrand,
      getCategoiesByBrandOptions,
      getCategoiesByBrandLoading
    }),

    [
      getCategoiesByBrandData,
      getCategoiesByBrandStatus,
      getCategoiesByBrand,
      getCategoiesByBrandOptions,
      getCategoiesByBrandLoading
    ]
  );
}

export default useGetCategoryByBrand;
