import React from "react";
import TableView from "@/common/components/TableView/TableView";
import { Grid, Button } from "@mui/material";
import { PlusOutlined } from "@ant-design/icons";
import { columns, reportFileName } from "./constants/constants";
import useEnergyTypeMaster from "./hooks/useEnergyTypeMaster";
import CreateEditEnergyTypeMaster from "./create-energytype-master/create-energytype-master";
import ModalDialog from "@/common/components/modal-dialog/modal-dialog";
import { FormattedMessage } from "react-intl";
import useOnActions from "./hooks/useOnActions";
import AlertDialog from "@/common/components/alert-dialog/alert-dialog";
import VerifyAccess from "@/common/components/verify-access/verify-access";
import { requestedMenuAll, requestedFeaturesAll } from "@/from-theme/routes/constants/menuAccess";
import { isUserHasAccess } from "@/from-theme/utils/route-guard/helpers/helpers";
import useAuth from "@/from-theme/hooks/useAuth";

const {energyTypeTabMasterKey} = requestedMenuAll;
const {createKey, updateKey, deleteKey} = requestedFeaturesAll;

export default function EnergyType() {
    const { energytypeMasterData, isCreateEditModalOpen, setIsCreateEditModalOpen, onRowEdit,
        activeEditId, closeModal, energytypeMasterStatus, energytypeMasterErrMsg }
         = useEnergyTypeMaster();
const { userData } = useAuth();
const entitlement = userData?.entitlement || {};

const isUpdateDeleteAccess = isUserHasAccess(energyTypeTabMasterKey, updateKey, entitlement)
|| isUserHasAccess(energyTypeTabMasterKey, deleteKey, entitlement);        

    const {
        onRowDelete,
        deleteEnergyTypeMasterStatus,
        isAlertDialog,
        onCloseAlert,
        onAgreeAlert
    } = useOnActions();
    const actionButton = (
        <VerifyAccess requestedMenu={energyTypeTabMasterKey} requestedFeature={createKey}>
        <Button
            variant="contained"
            startIcon={<PlusOutlined />}
            onClick={() => setIsCreateEditModalOpen(true)}
        ><FormattedMessage id="add-energytype" />
        </Button>
        </VerifyAccess>
    );

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TableView
                    data={energytypeMasterData}
                    columns={columns({ onRowEdit, onRowDelete, deleteEnergyTypeMasterStatus,
                        isUpdateDeleteAccess })}
                    actionButton={actionButton}
                    reportFileName={reportFileName}
                    apiStatus={energytypeMasterStatus}
                    apiErrorMsg={energytypeMasterErrMsg}
                    stringInExport={["activeStatus"]}
                />
                {isCreateEditModalOpen && (
                    <ModalDialog
                        closeModal={() => closeModal()}
                        modalTitle={!activeEditId ? "create-energytype-master" :
                         "edit-energytype-master"}
                    >
                        <CreateEditEnergyTypeMaster closeModal={() => closeModal()}
                            activeEditId={activeEditId} />
                    </ModalDialog>
                )}
                {isAlertDialog && (
                    <AlertDialog onCloseAlert={onCloseAlert} onAgreeAlert={onAgreeAlert} />
                )}
            </Grid>
        </Grid>
    );
}
