import React from "react";
import TableView from "@/common/components/TableView/TableView";
import { Grid, Button } from "@mui/material";
import { PlusOutlined } from "@ant-design/icons";
import { columns, reportFileName } from "./constants/constants";
import useCityMaster from "./hooks/useCityMaster";
import ModalDialog from "@/common/components/modal-dialog/modal-dialog";
import CreateEditCityMaster from "./create-city-master/create-city-master";
import { FormattedMessage } from "react-intl";
import useOnActions from "./hooks/useOnActions";
import AlertDialog from "@/common/components/alert-dialog/alert-dialog";
import useAuth from "@/from-theme/hooks/useAuth";
import VerifyAccess from "@/common/components/verify-access/verify-access";
import { requestedMenuAll, requestedFeaturesAll } from "@/from-theme/routes/constants/menuAccess";
import { isUserHasAccess } from "@/from-theme/utils/route-guard/helpers/helpers";

const {cityTabMasterKey} = requestedMenuAll;
const {createKey, updateKey, deleteKey} = requestedFeaturesAll;

const CityMaster = () => {
    const { cityMasterData, isCreateEditModalOpen, setIsCreateEditModalOpen, onRowEdit,
        activeEditId, closeModal, cityMasterStatus, cityMasterErrMsg } = useCityMaster();

    const {
        onRowDelete,
        deleteCityMasterStatus,
        isAlertDialog,
        onCloseAlert,
        onAgreeAlert
    } = useOnActions();
    const { userData } = useAuth();
    const entitlement = userData?.entitlement || {};

const isUpdateDeleteAccess = 
isUserHasAccess(cityTabMasterKey, updateKey, entitlement) || 
isUserHasAccess(cityTabMasterKey, deleteKey, entitlement); 
    const actionButton = (
        <VerifyAccess requestedMenu={cityTabMasterKey} requestedFeature={createKey}>
        <Button
            variant="contained"
            startIcon={<PlusOutlined />}
            onClick={() => setIsCreateEditModalOpen(true)}
        ><FormattedMessage id="add-city" />
        </Button>
        </VerifyAccess>
    );


    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TableView
                    data={cityMasterData}
                    columns={columns({ onRowEdit, onRowDelete, deleteCityMasterStatus,
                        isUpdateDeleteAccess
                     })}
                    actionButton={actionButton}
                    reportFileName={reportFileName}
                    apiStatus={cityMasterStatus}
                    apiErrorMsg={cityMasterErrMsg}
                    stringInExport={["activeStatus"]}
                />
                {isCreateEditModalOpen && (
                    <ModalDialog
                        closeModal={() => closeModal()}
                        modalTitle={!activeEditId ? "create-city-master" : "edit-city-master"}
                    >
                        <CreateEditCityMaster closeModal={() => closeModal()}
                            activeEditId={activeEditId} />
                    </ModalDialog>
                )}
                {isAlertDialog && (
                    <AlertDialog onCloseAlert={onCloseAlert} onAgreeAlert={onAgreeAlert} />
                )}
            </Grid>
        </Grid>
    );
};

export default CityMaster;