import TabsView from "@/common/components/tabs-view/tabs-view";
import React from "react";
import { settingsTabs } from "./constants/constants";

export default function ZoneMaster() {
    return(
        <TabsView
            tabs={settingsTabs}
        />
    )
}