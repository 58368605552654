import React from "react";
import TableView from "@/common/components/TableView/TableView";
import { Grid, Button } from "@mui/material";
import { PlusOutlined } from "@ant-design/icons";
import { columns, reportFileName } from "./constants/constants";
import useMenuMaster from "./hooks/useMenuMaster";
import CreateEditMenuMaster from "./create-edit-menu-master/create-edit-menu-master";
import ModalDialog from "@/common/components/modal-dialog/modal-dialog";
import { FormattedMessage } from "react-intl";
import useOnActions from "./hooks/useOnActions";
import AlertDialog from "@/common/components/alert-dialog/alert-dialog";
import VerifyAccess from "@/common/components/verify-access/verify-access";
import { requestedMenuAll, requestedFeaturesAll } from "@/from-theme/routes/constants/menuAccess";
import { isUserHasAccess } from "@/from-theme/utils/route-guard/helpers/helpers";
import useAuth from "@/from-theme/hooks/useAuth";

const {menuTabMasterKey} = requestedMenuAll;
const {createKey, updateKey, deleteKey} = requestedFeaturesAll;

export default function Brand() {
    const { menuMasterData, isCreateEditModalOpen, setIsCreateEditModalOpen, onRowEdit,
        activeEditId, closeModal, menuMasterStatus, menuMasterErrMsg } = useMenuMaster();
    const { userData } = useAuth();
    const entitlement = userData?.entitlement || {};
    
    const isUpdateDeleteAccess = isUserHasAccess(menuTabMasterKey, updateKey, entitlement)
        || isUserHasAccess(menuTabMasterKey, deleteKey, entitlement);       
    const {
        onRowDelete,
        deleteMenuMasterStatus,
        isAlertDialog,
        onCloseAlert,
        onAgreeAlert
    } = useOnActions();

    const actionButton = (
    <VerifyAccess requestedMenu={menuTabMasterKey} requestedFeature={createKey}>

        <Button
            variant="contained"
            startIcon={<PlusOutlined />}
            onClick={() => setIsCreateEditModalOpen(true)}
        ><FormattedMessage id="add-menu" />
        </Button>
        </VerifyAccess>
    );

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TableView
                    data={menuMasterData}
                    columns={columns({ onRowEdit, onRowDelete, deleteMenuMasterStatus,
                        isUpdateDeleteAccess })}
                    actionButton={actionButton}
                    reportFileName={reportFileName}
                    apiStatus={menuMasterStatus}
                    apiErrorMsg={menuMasterErrMsg}
                    stringInExport={["activeStatus"]}
                />
                {isCreateEditModalOpen && (
                    <ModalDialog
                        closeModal={() => closeModal()}
                        modalTitle={!activeEditId ? "create-menu-master" :
                         "edit-menu-master"}
                    >
                        <CreateEditMenuMaster closeModal={() => closeModal()}
                            activeEditId={activeEditId} />
                    </ModalDialog>
                )}
                {isAlertDialog && (
                    <AlertDialog onCloseAlert={onCloseAlert} onAgreeAlert={onAgreeAlert} />
                )}
            </Grid>
        </Grid>
    );
}
