import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { clearData, fetchData } from '@/store/slices/fetch-slice';
import { getData, getStatus } from '@/store/slices/helpers/helpers';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetDepartmentMasterByDivision(division) {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getDepartmentsByDivisionData = 
    getData(state, apiKeys.departmentListByDivision(division), []);
  const getDepartmentsByDivisionStatus = 
    getStatus(state, apiKeys.departmentListByDivision(division), '');
  const getDepartmentsByDivisionLoading = apiStatuses.pending === getDepartmentsByDivisionStatus;
  
  const getDepartmentsByDivision = useCallback((inputId) => {
    dispatch(fetchData({
      url: apiUrls.departmentList,
      method: apiMethods.POST,
      key: apiKeys.departmentListByDivision(division),
      body: inputId,
    }))
  }, [division, dispatch]);

  const getDepartmentsByDivisionOptions = useMemo(() => {
    return getDepartmentsByDivisionData
    .map(({ departmentId, departmentName, }) => ({
      id: departmentId,
      label: departmentName,
    }))
  }, [getDepartmentsByDivisionData]);


  useEffect(
    () => () => {
      dispatch(clearData({ key: apiKeys.departmentListByDivision(division) }));
    },
    [division, dispatch]
  );

  return useMemo(
    () => ({
      getDepartmentsByDivisionData,
      getDepartmentsByDivisionStatus,
      getDepartmentsByDivision,
      getDepartmentsByDivisionOptions,
      getDepartmentsByDivisionLoading
    }),

    [
      getDepartmentsByDivisionData,
      getDepartmentsByDivisionStatus,
      getDepartmentsByDivision,
      getDepartmentsByDivisionOptions,
      getDepartmentsByDivisionLoading
    ]
  );
}

export default useGetDepartmentMasterByDivision;
