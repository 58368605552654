import React from "react";
import TableView from "@/common/components/TableView/TableView";
import { Grid, Button } from "@mui/material";
import { PlusOutlined } from "@ant-design/icons";
import { columns, reportFileName } from "./constants/constants";
import useStateMaster from "./hooks/useStateMaster";
import CreateEditStateMaster from "./create-state-master/create-state-master";
import ModalDialog from "@/common/components/modal-dialog/modal-dialog";
import { FormattedMessage } from "react-intl";
import useOnActions from "./hooks/useOnActions";
import AlertDialog from "@/common/components/alert-dialog/alert-dialog";
import useAuth from "@/from-theme/hooks/useAuth";
import VerifyAccess from "@/common/components/verify-access/verify-access";
import { requestedMenuAll, requestedFeaturesAll } from "@/from-theme/routes/constants/menuAccess";
import { isUserHasAccess } from "@/from-theme/utils/route-guard/helpers/helpers";

const {stateTabMasterKey} = requestedMenuAll;
const {createKey, updateKey, deleteKey} = requestedFeaturesAll;


const StateMaster = () => {
    const { stateMasterData, isCreateEditModalOpen, setIsCreateEditModalOpen, onRowEdit,
        activeEditId, closeModal, stateMasterStatus, stateMasterErrMsg } = useStateMaster();
    const {
        onRowDelete,
        deleteStateMasterStatus,
        isAlertDialog,
        onCloseAlert,
        onAgreeAlert
    } = useOnActions();
    const { userData } = useAuth();
    const entitlement = userData?.entitlement || {};

const isUpdateDeleteAccess = 
isUserHasAccess(stateTabMasterKey, updateKey, entitlement) || 
isUserHasAccess(stateTabMasterKey, deleteKey, entitlement); 
    const actionButton = (
        <VerifyAccess requestedMenu={stateTabMasterKey} requestedFeature={createKey}>
        <Button
            variant="contained"
            startIcon={<PlusOutlined />}
            onClick={() => setIsCreateEditModalOpen(true)}
        ><FormattedMessage id="add-state" />
        </Button>
        </VerifyAccess>
    );

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TableView
                    data={stateMasterData}
                    columns={columns({ onRowEdit, onRowDelete, deleteStateMasterStatus,
                        isUpdateDeleteAccess
                     })}
                    actionButton={actionButton}
                    reportFileName={reportFileName}
                    apiStatus={stateMasterStatus}
                    apiErrorMsg={stateMasterErrMsg}
                    stringInExport={["activeStatus"]}
                />
                {isCreateEditModalOpen && (
                    <ModalDialog
                        closeModal={() => closeModal()}
                        modalTitle={!activeEditId ? "create-state-master" :
                         "edit-state-master"}
                        maxWidth="md" 
                    >
                        <CreateEditStateMaster closeModal={() => closeModal()}
                            activeEditId={activeEditId} />
                    </ModalDialog>
                )}
                {isAlertDialog && (
                    <AlertDialog onCloseAlert={onCloseAlert} onAgreeAlert={onAgreeAlert} />
                )}
            </Grid>
        </Grid>
    );
};

export default StateMaster;