import React from "react";
import TableView from "@/common/components/TableView/TableView";
import { Grid, Button } from "@mui/material";
import { PlusOutlined } from "@ant-design/icons";
import { columns, reportFileName } from "./constants/constants";
import useAccommodationMaster from "./hooks/useAccommodationMaster";
import CreateEditAccommodationMaster 
from "./create-accommodation-master/create-accommodation-master";
import ModalDialog from "@/common/components/modal-dialog/modal-dialog";
import { FormattedMessage } from "react-intl";
import useOnActions from "./hooks/useOnActions";
import AlertDialog from "@/common/components/alert-dialog/alert-dialog";
import VerifyAccess from "@/common/components/verify-access/verify-access";
import { requestedMenuAll, requestedFeaturesAll } from "@/from-theme/routes/constants/menuAccess";
import { isUserHasAccess } from "@/from-theme/utils/route-guard/helpers/helpers";
import useAuth from "@/from-theme/hooks/useAuth";

const {accommodationTabMasterKey} = requestedMenuAll;
const {createKey, updateKey, deleteKey} = requestedFeaturesAll;

export default function Accommodation() {
    const { accommodationMasterData, isCreateEditModalOpen, setIsCreateEditModalOpen, onRowEdit,
        activeEditId, closeModal, accommodationMasterStatus, accommodationMasterErrMsg } =
        useAccommodationMaster();
        const { userData } = useAuth();
        const entitlement = userData?.entitlement || {};
      
        const isUpdateDeleteAccess = 
        isUserHasAccess(accommodationTabMasterKey, updateKey, entitlement) || 
        isUserHasAccess(accommodationTabMasterKey, deleteKey, entitlement); 
          
    const {
        onRowDelete,
        deleteAccommodationMasterStatus,

        isAlertDialog,
        onCloseAlert,
        onAgreeAlert
    } = useOnActions();

    const actionButton = (
    <VerifyAccess requestedMenu={accommodationTabMasterKey} requestedFeature={createKey}>
        <Button
            variant="contained"
            startIcon={<PlusOutlined />}
            onClick={() => setIsCreateEditModalOpen(true)}
        ><FormattedMessage id="add-accommodation" />
        </Button>
    </VerifyAccess>
    );

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TableView
                    data={accommodationMasterData}
                    columns={columns({ onRowEdit, onRowDelete, deleteAccommodationMasterStatus,
                        isUpdateDeleteAccess })}
                    actionButton={actionButton}
                    reportFileName={reportFileName}
                    apiStatus={accommodationMasterStatus}
                    apiErrorMsg={accommodationMasterErrMsg}
                    stringInExport={["activeStatus"]}
                />
                {isCreateEditModalOpen && (
                    <ModalDialog
                        closeModal={() => closeModal()}
                        modalTitle={!activeEditId ? "create-accommodation-master" :
                         "edit-accommodation-master"}
                    >
                        <CreateEditAccommodationMaster closeModal={() => closeModal()}
                            activeEditId={activeEditId} />
                    </ModalDialog>
                )}
                {isAlertDialog && (
                    <AlertDialog onCloseAlert={onCloseAlert} onAgreeAlert={onAgreeAlert} />
                )}
            </Grid>
        </Grid>
    );
}
