
export const initialValues = {
  materialCode: null,
  activeStatus: 1,
  materialName: null,
  buom: null,
  grossWeight: null,
  netWeight: null,
  categoryId: undefined,
  materialTypeId: undefined,
  brandId: undefined,
  productId: undefined,
  auom: null,
  conversionFactor: null,
  controlCode: null,
  sku: null,
  deletionIndicator: null,

}
