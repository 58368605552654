import React from "react";
import TableView from "@/common/components/TableView/TableView";
import { Grid, Button } from "@mui/material";
import { PlusOutlined } from "@ant-design/icons";
import { columns, reportFileName } from "./constants/constants";
import useBrandMaster from "./hooks/useBrandMaster";
import CreateEditBrandMaster from "./create-brand-master/create-brand-master";
import ModalDialog from "@/common/components/modal-dialog/modal-dialog";
import { FormattedMessage } from "react-intl";
import useOnActions from "./hooks/useOnActions";
import AlertDialog from "@/common/components/alert-dialog/alert-dialog";

import VerifyAccess from "@/common/components/verify-access/verify-access";
import { requestedMenuAll, requestedFeaturesAll } from "@/from-theme/routes/constants/menuAccess";
import { isUserHasAccess } from "@/from-theme/utils/route-guard/helpers/helpers";
import useAuth from "@/from-theme/hooks/useAuth";

const {brandTabMasterKey} = requestedMenuAll;
const {createKey, updateKey, deleteKey} = requestedFeaturesAll;

export default function Brand() {
    const { brandMasterData, isCreateEditModalOpen, setIsCreateEditModalOpen, onRowEdit,
        activeEditId, closeModal, brandMasterStatus, brandMasterErrMsg } = useBrandMaster();
const { userData } = useAuth();
const entitlement = userData?.entitlement || {};

const isUpdateDeleteAccess = isUserHasAccess(brandTabMasterKey, updateKey, entitlement)
    || isUserHasAccess(brandTabMasterKey, deleteKey, entitlement);
             const {
        onRowDelete,
        deleteBrandMasterStatus,
        isAlertDialog,
        onCloseAlert,
        onAgreeAlert
    } = useOnActions();

    const actionButton = (
    <VerifyAccess requestedMenu={brandTabMasterKey} requestedFeature={createKey}>
        <Button
            variant="contained"
            startIcon={<PlusOutlined />}
            onClick={() => setIsCreateEditModalOpen(true)}
        ><FormattedMessage id="add-brand" />
        </Button>
        </VerifyAccess>
    );

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TableView
                    data={brandMasterData}
                    columns={columns({ onRowEdit, onRowDelete, deleteBrandMasterStatus,
                        isUpdateDeleteAccess })}
                    actionButton={actionButton}
                    reportFileName={reportFileName}
                    apiStatus={brandMasterStatus}
                    apiErrorMsg={brandMasterErrMsg}
                    stringInExport={["activeStatus"]}
                />
                {isCreateEditModalOpen && (
                    <ModalDialog
                        closeModal={() => closeModal()}
                        modalTitle={!activeEditId ? "create-brand-master" :
                         "edit-brand-master"}
                    >
                        <CreateEditBrandMaster closeModal={() => closeModal()}
                            activeEditId={activeEditId} />
                    </ModalDialog>
                )}
                {isAlertDialog && (
                    <AlertDialog onCloseAlert={onCloseAlert} onAgreeAlert={onAgreeAlert} />
                )}
            </Grid>
        </Grid>
    );
}
