import React from "react";
import {
  Button, DialogActions, DialogContent, Grid
} from '@mui/material';
import TextInput from "@/common/components/text-input/text-input";
import SwitchInput from "@/common/components/switch-input/switch-input";
import Dropdown from '@/common/components/dropdown/dropdown';
import DateInput from '@/common/components/date-input/date-input';
import AnimateButton from "@/from-theme/components/@extended/AnimateButton";
import { FormattedMessage } from 'react-intl';
import useCreateEditAccommodationMaster from "./hooks/useCreateEditAccommodationMaster";
import PropTypes from "prop-types";
import { apiStatuses } from "@/store/api/constants";
import ErrorInPage from "@/common/components/error-in-page/error-in-page";
import Spinner from "@/common/components/spinner/spinner";
import useGetGradeData from "@/common/hooks/useGetGradeMaster";
import dayjs from "dayjs";
import useGetDropdownListByDropdown from "@/common/hooks/useGetDropdownListByDropdown";

function CreateEditAccommodationMaster({
  closeModal, activeEditId
}) {

  const { getGradeOptions, getGradeLoading } = useGetGradeData();
  
  const {
    getDropdownListByDropdownData: dropdownListData,
    getDropdownListByDropdown: getDropdownList,
    getDropdownListByDropdownLoading: dropdownListLoading
  } = useGetDropdownListByDropdown('dropdown');

  const {
    values, formik, getEditAccommodationMasterStatus,
    getEditAccommodationMasterErrMsg
  } = useCreateEditAccommodationMaster({activeEditId, getDropdownList});

  const currentDate = new Date();
  const minDate = dayjs(currentDate).subtract(0, 'day'); 
  const maxDate = dayjs(currentDate).add(10, 'day'); 
  const {
    accomGradeId, accomCategory, lodgingCost, boardingCost, accomValidFrom,
    accomValidTo, activeStatus
  } = values;


  return (
    <>
      {
        getEditAccommodationMasterStatus === apiStatuses.pending && activeEditId
        && <Spinner />
      }

      {getEditAccommodationMasterStatus === apiStatuses.rejected && activeEditId && (
        <ErrorInPage message={getEditAccommodationMasterErrMsg} />
      )}

      {(getEditAccommodationMasterStatus === apiStatuses.succeeded || !activeEditId) &&
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <Grid container spacing={1.25}  style={{ paddingBottom: '225px' }}>
            <Grid item xs={12} sm={6} md={4} lg={6}>
                <Dropdown
                  options={getGradeOptions}
                  loading={getGradeLoading}
                  placeholder="grade"
                  inputLabel="grade"
                  value={accomGradeId}
                  formik={formik}
                  name="accomGradeId"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <Dropdown
                  options={dropdownListData}
                  loading={dropdownListLoading}
                  placeholder="accom-category"
                  inputLabel="accom-category"
                  value={accomCategory}
                  formik={formik}
                  name="accomCategory"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="lodging-cost"
                  size="small"
                  fullWidth
                  value={lodgingCost}
                  inputLabel="lodging-cost"
                  formik={formik}
                  name="lodgingCost"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="boarding-cost"
                  size="small"
                  fullWidth
                  value={boardingCost}
                  inputLabel="boarding-cost"
                  formik={formik}
                  name="boardingCost"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <DateInput inputLabel="valid-from" placeholder="valid-from"  maxDate={maxDate}
                  minDate={minDate} 
                value={accomValidFrom} formik={formik} name="accomValidFrom" />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <DateInput inputLabel="valid-to" placeholder="valid-to" minDate={minDate}
                 value={accomValidTo} formik={formik} name="accomValidTo" />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <SwitchInput
                  inputLabel="activeStatus"
                  leftLabel="inactive"
                  rightLabel="active"
                  value={activeStatus}
                  formik={formik}
                  name="activeStatus"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={closeModal} variant="outlined" sx={{ mr: 1 }}>
              <FormattedMessage id={"cancel"} />
            </Button>
            <AnimateButton>
              <Button type="submit" variant="contained" sx={{ mr: 1 }}>
                <FormattedMessage id={"save"} />
              </Button>
            </AnimateButton>
          </DialogActions>
        </form>
      }
    </>
  )
}

CreateEditAccommodationMaster.propTypes = {
  closeModal: PropTypes.func.isRequired,
  activeEditId: PropTypes.bool,
};

CreateEditAccommodationMaster.defaultProps = {
  activeEditId: 0,
};



export default CreateEditAccommodationMaster;