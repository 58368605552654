import React from 'react';
import TableView from '@/common/components/TableView/TableView';
import { Grid, Button } from '@mui/material';
import { PlusOutlined } from '@ant-design/icons';
import { columns, reportFileName } from './constants/constants';
import useAllowanceMaster from './hooks/useAllowanceMaster';
import CreateEditAllowanceMaster from './create-allowance-master/create-allowance-master';
import ModalDialog from '@/common/components/modal-dialog/modal-dialog';
import { FormattedMessage } from 'react-intl';
import useOnActions from './hooks/useOnActions';
import AlertDialog from '@/common/components/alert-dialog/alert-dialog';
import VerifyAccess from '@/common/components/verify-access/verify-access';
import { requestedMenuAll, requestedFeaturesAll } from '@/from-theme/routes/constants/menuAccess';
import { isUserHasAccess } from '@/from-theme/utils/route-guard/helpers/helpers';
import useAuth from '@/from-theme/hooks/useAuth';

const { allowanceTabMasterKey } = requestedMenuAll;
const { createKey, updateKey, deleteKey } = requestedFeaturesAll;

export default function Allowance() {
  const {
    allowanceMasterData,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal,
    allowanceMasterStatus,
    allowanceMasterErrMsg
  } = useAllowanceMaster();
  const { userData } = useAuth();
  const entitlement = userData?.entitlement || {};

  const isUpdateDeleteAccess =
    isUserHasAccess(allowanceTabMasterKey, updateKey, entitlement) || 
    isUserHasAccess(allowanceTabMasterKey, deleteKey, entitlement);
  const { onRowDelete, deleteAllowanceMasterStatus, isAlertDialog, 
    onCloseAlert, onAgreeAlert } = useOnActions();

  const actionButton = (
    <VerifyAccess requestedMenu={allowanceTabMasterKey} requestedFeature={createKey}>
    <Button variant="contained" startIcon={<PlusOutlined />} onClick={() =>
     setIsCreateEditModalOpen(true)}>
      <FormattedMessage id="add-allowance" />
    </Button>
    </VerifyAccess>
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TableView
          data={allowanceMasterData}
          columns={columns({ onRowEdit, onRowDelete, deleteAllowanceMasterStatus, 
            isUpdateDeleteAccess })}
          actionButton={actionButton}
          reportFileName={reportFileName}
          apiStatus={allowanceMasterStatus}
          apiErrorMsg={allowanceMasterErrMsg}
          stringInExport={["activeStatus"]}
        />
        {isCreateEditModalOpen && (
          <ModalDialog closeModal={() => closeModal()} modalTitle={!activeEditId ? 
          'create-allowance-master' : 'edit-allowance-master'}>
            <CreateEditAllowanceMaster closeModal={() => closeModal()}
             activeEditId={activeEditId} />
          </ModalDialog>
        )}
        {isAlertDialog && <AlertDialog onCloseAlert={onCloseAlert} onAgreeAlert={onAgreeAlert} />}
      </Grid>
    </Grid>
  );
}
