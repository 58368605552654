import { Chip, Grid } from "@mui/material";
import EditAction from "../components/edit-action/edit-action";
import DeleteAction from "../components/delete-action/delete-action";
import { dateString } from "@/common/components/TableView/helpers/helpers";
import VerifyAccess from "@/common/components/verify-access/verify-access";
import { requestedMenuAll, requestedFeaturesAll } from "@/from-theme/routes/constants/menuAccess";
const {districtTabMasterKey} = requestedMenuAll;
const {updateKey, deleteKey} = requestedFeaturesAll;

export const columns = ({ onRowEdit, onRowDelete, deleteDistrictMasterStatus,
  isUpdateDeleteAccess
 }) => ([
  {
    header: 'sno',
    accessorKey: 'districtId',
    cell: (value) => {
      const districtId = value.row.index + 1;
     return districtId;
    }
  },
  {
    header: 'key-id',
    accessorKey: 'districtId'
  },
  {
    header: 'district-name',
    accessorKey: 'districtName'
  },
  {
    header: 'state-name',
    accessorKey: 'stateName'
  },
  {
    header: 'country-name',
    accessorKey: 'countryName'
  },
  {
    header: 'created-at',
    hideColumn: true,
    accessorKey: 'createdAt',
    cell: ({ getValue }) => dateString(getValue()) 
  },
  {
    header: 'created-by',
    hideColumn: true,
    accessorKey: 'createdBy'
  },
  {
    header: 'updated-at',
    hideColumn: true,
    accessorKey: 'updatedAt',
    cell: ({ getValue }) => dateString(getValue()) 
  },
  {
    header: 'updated-by',
    hideColumn: true,
    accessorKey: 'updatedBy'
  },
  {
    header: 'status',
    accessorKey: 'activeStatus',
    cell: (cell) => {
      switch (cell.getValue()) {
        case '0':
          return <Chip color="error" label="In-Active" size="small" variant="light" />;
        case '1':
          return <Chip color="success" label="Active" size="small" variant="light" />;

      }
    }
  },
 
  ...(isUpdateDeleteAccess ? [
    {
      header: 'action',
      id: "edit",
      cell: (props) => (
        <Grid container className="table-icon-actions-2">
          <VerifyAccess requestedMenu={districtTabMasterKey} requestedFeature={updateKey}>
          <Grid item>
            <EditAction
              {...props}
              onRowEdit={onRowEdit}
            />
          </Grid>
          </VerifyAccess>
          <VerifyAccess requestedMenu={districtTabMasterKey} requestedFeature={deleteKey}>
          <Grid item>
            <DeleteAction
              {...props}
              onRowDelete={onRowDelete} deleteDistrictMasterStatus={deleteDistrictMasterStatus}
            />
          </Grid>
          </VerifyAccess>
        </Grid>
      ),
      meta: {
        className: 'cell-center'
      }
    }
  
  ]: [])]);

export const reportFileName = "District Master";


