import React from 'react';
import TableView from '@/common/components/TableView/TableView';
import { Grid, Button } from '@mui/material';
import { PlusOutlined } from '@ant-design/icons';
import { columns, reportFileName } from './constants/constants';
import useEnergyPriceMaster from './hooks/useEnergyPriceMaster';
import CreateEditEnergyPriceMaster from './create-energyprice-master/create-energyprice-master';
import ModalDialog from '@/common/components/modal-dialog/modal-dialog';
import { FormattedMessage } from 'react-intl';
import useOnActions from './hooks/useOnActions';
import AlertDialog from '@/common/components/alert-dialog/alert-dialog';
import VerifyAccess from '@/common/components/verify-access/verify-access';
import { requestedMenuAll, requestedFeaturesAll } from '@/from-theme/routes/constants/menuAccess';
import { isUserHasAccess } from '@/from-theme/utils/route-guard/helpers/helpers';
import useAuth from '@/from-theme/hooks/useAuth';

const { energyPriceTabMasterKey } = requestedMenuAll;
const { createKey, updateKey, deleteKey } = requestedFeaturesAll;

const EnergyPriceMaster = () => {
  const {
    energypriceMasterData,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal,
    energypriceMasterStatus,
    energypriceMasterErrMsg
  } = useEnergyPriceMaster();
  const { userData } = useAuth();
  const entitlement = userData?.entitlement || {};

  const isUpdateDeleteAccess =
    isUserHasAccess(energyPriceTabMasterKey, updateKey, entitlement) ||
     isUserHasAccess(energyPriceTabMasterKey, deleteKey, entitlement);
  const { onRowDelete, deleteEnergyPriceMasterStatus, isAlertDialog, onCloseAlert,
     onAgreeAlert } = useOnActions();
  const actionButton = (
    <VerifyAccess requestedMenu={energyPriceTabMasterKey} requestedFeature={createKey}>
    <Button variant="contained" startIcon={<PlusOutlined />} onClick={() => 
    setIsCreateEditModalOpen(true)}>
      <FormattedMessage id="add-energyprice" />
    </Button>
    </VerifyAccess>
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TableView
          data={energypriceMasterData}
          columns={columns({ onRowEdit, onRowDelete, deleteEnergyPriceMasterStatus,
             isUpdateDeleteAccess })}
          actionButton={actionButton}
          reportFileName={reportFileName}
          apiStatus={energypriceMasterStatus}
          apiErrorMsg={energypriceMasterErrMsg}
          stringInExport={["activeStatus"]}
        />
        {isCreateEditModalOpen && (
          <ModalDialog closeModal={() => closeModal()} modalTitle={!activeEditId ? 
          'create-energyprice-master' : 'edit-energyprice-master'}>
            <CreateEditEnergyPriceMaster closeModal={() => closeModal()}
             activeEditId={activeEditId} />
          </ModalDialog>
        )}
        {isAlertDialog && <AlertDialog onCloseAlert={onCloseAlert} onAgreeAlert={onAgreeAlert} />}
      </Grid>
    </Grid>
  );
};

export default EnergyPriceMaster;
