import { useFormik } from 'formik';
import * as yup from 'yup';
import { useCallback, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getData, getErrorMessage, getStatus } from '@/store/slices/helpers/helpers';
import { fetchData, clearMultipleData } from '@/store/slices/fetch-slice';
import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { initialValues } from '../constants/constants';
import { getFormattedPayload, getFormattedResponse } from '@/common/helpers/helpers';

function useCreateEditAccommodationMaster({activeEditId, getDropdownList}) {
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveAccommodationMasterStatus = getStatus(state, apiKeys.saveAccommodationMaster, '');
  const saveAccommodationMasterLoading = apiStatuses.pending === saveAccommodationMasterStatus;
  const getEditAccommodationMasterData = getData(state, apiKeys.getEditAccommodationMaster, {});
  const getEditAccommodationMasterStatus = getStatus(state, apiKeys.getEditAccommodationMaster, '');
  const getEditAccommodationMasterErrMsg = getErrorMessage(
    state,
    apiKeys.getEditAccommodationMaster,
    formatMessage({ id: 'failed-to-get-selected-accommodation-details' })
  );

  const validationSchema = yup.object({
    accomGradeId: yup.object().required(formatMessage({ id: 'this-field-is-required' })),
    accomCategory: yup.string()
    .matches(/^[A-Da-d\s]+$/, formatMessage({ id: 'only-a,b,c,d-allowed' }))
    .required(formatMessage({ id: 'this-field-is-required' })),
    lodgingCost: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    boardingCost: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    accomValidFrom: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    accomValidTo: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    activeStatus: yup.boolean().required(formatMessage({ id: 'this-field-is-required' }))
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (activeEditId) {
        updateAccommodationMaster(values);
      } else {
        saveAccommodationMaster(values);
      }
    }
  });


  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;

  const getEditAccommodationMaster = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getEditAccommodationMaster(activeEditId),
        method: apiMethods.GET,
        key: apiKeys.getEditAccommodationMaster,
        failureMsg: formatMessage({ id: 'failed-to-get-selected-accommodation-details' })
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(() => {
    if (activeEditId) {
      getEditAccommodationMaster();
    }
  }, [activeEditId, getEditAccommodationMaster]);

  useEffect(() => {
      getDropdownList(31);
  }, [getDropdownList]);

  useEffect(() => {
    if (getEditAccommodationMasterStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getEditAccommodationMasterData));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEditAccommodationMasterStatus, getEditAccommodationMasterData]);

  const saveAccommodationMaster = useCallback(
    (values) => {
      dispatch(
        fetchData({
          url: apiUrls.saveAccommodationMaster,
          method: apiMethods.PUT,
          key: apiKeys.saveAccommodationMaster,
          successMsg: formatMessage({ id: 'accommodation-master-is-saved-successfully' }),
          failureMsg: formatMessage({ id: 'accommodation-master-is-failed-to-save' }),
          body: getFormattedPayload(values)
        })
      );
    },
    [dispatch, formatMessage]
  );

  const updateAccommodationMaster = useCallback(
    (values) => {
      dispatch(
        fetchData({
          url: apiUrls.updateAccommodationMaster,
          method: apiMethods.PUT,
          key: apiKeys.updateAccommodationMaster,
          successMsg: formatMessage({ id: 'accommodation-master-is-updated-successfully' }),
          failureMsg: formatMessage({ id: 'accommodation-master-is-failed-to-update' }),
          body: getFormattedPayload({ ...values, id: activeEditId })
        })
      );
    },
    [dispatch, formatMessage, activeEditId]
  );


  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: [apiKeys.saveAccommodationMaster, 
        apiKeys.getEditAccommodationMaster] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      formik,
      values: formik.values,
      saveAccommodationMasterStatus,
      saveAccommodationMasterLoading,
      getEditAccommodationMasterData,
      getEditAccommodationMasterStatus,
      getEditAccommodationMasterErrMsg
    }),
    [formik, saveAccommodationMasterStatus,
      saveAccommodationMasterLoading,
      getEditAccommodationMasterData, 
      getEditAccommodationMasterStatus,
      getEditAccommodationMasterErrMsg]
  );
}

export default useCreateEditAccommodationMaster;
