import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { clearData, fetchData } from '@/store/slices/fetch-slice';
import { getData, getStatus } from '@/store/slices/helpers/helpers';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetUserByDivision() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getUserListData = getData(state, apiKeys.getEditUserByDivision, []);
  const getUserListStatus = getStatus(state, apiKeys.getEditUserByDivision, '');
  const getUserListLoading = apiStatuses.pending === getUserListStatus;

  //*************************this apis based on division data****************************
  const getUserList = useCallback((inputId) => {
    dispatch(fetchData({
      url: apiUrls.getEditUserByDivision,
      method: apiMethods.POST,
      key: apiKeys.getEditUserByDivision,
      body: inputId,
    }))
  }, [dispatch]);

  const getUserListOptions = useMemo(() => {
    return getUserListData
    .map(({ userId, employeeName }) => ({
      id: userId,
      label: employeeName
    }))
  }, [getUserListData]);

  const getArmName = useMemo(() => {
    return getUserListData.filter((option) => option.empRoleId === 2 )
    .map(({ userId, employeeName }) => ({
      id: userId,
      label: employeeName
    }))
  }, [getUserListData]);

  const getRmName = useMemo(() => {
    return getUserListData.filter((option) => option.empRoleId === 3 )
    .map(({ userId, employeeName }) => ({
      id: userId,
      label: employeeName
    }))
  }, [getUserListData]);

  const getZHName = useMemo(() => {
    return getUserListData.filter((option) => option.empRoleId === 4 )
    .map(({ userId, employeeName }) => ({
      id: userId,
      label: employeeName
    }))
  }, [getUserListData]);

  const getSHName = useMemo(() => {
    return getUserListData.filter((option) => option.empRoleId === 5 )
    .map(({ userId, employeeName }) => ({
      id: userId,
      label: employeeName
    }))
  }, [getUserListData]);

  const getBHName = useMemo(() => {
    return getUserListData.filter((option) => option.empRoleId === 58 )
    .map(({ userId, employeeName }) => ({
      id: userId,
      label: employeeName
    }))
  }, [getUserListData]);

  //*************************this apis based on division data****************************


  useEffect(
    () => () => {
      dispatch(clearData({ key: [apiKeys.userMaster,] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      getUserList,
      getUserListData,
      getUserListStatus,
      getUserListLoading,
      getUserListOptions,
      getArmName,
      getRmName,
      getZHName,
      getSHName,
      getBHName
    }),
    [
       getUserList,
      getUserListData, getUserListStatus,
      getUserListLoading, getUserListOptions,
      getArmName, getRmName, getZHName, getSHName, getBHName ]
  );
}

export default useGetUserByDivision;
