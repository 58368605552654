import React from "react";
import {
  Button, DialogActions, DialogContent, Grid
} from '@mui/material';
import TextInput from "@/common/components/text-input/text-input";
import Dropdown from "@/common/components/dropdown/dropdown";
import SwitchInput from "@/common/components/switch-input/switch-input";
import AnimateButton from "@/from-theme/components/@extended/AnimateButton";
import { FormattedMessage } from 'react-intl';
import useCreateEditEnergyTypeMaster from "./hooks/useCreateEditEnergyTypeMaster";
import PropTypes from "prop-types";
import { apiStatuses } from "@/store/api/constants";
import ErrorInPage from "@/common/components/error-in-page/error-in-page";
import Spinner from "@/common/components/spinner/spinner";
import useGetDropdownListOptions from "@/common/hooks/useGetDropdownListMaster";

function CreateEditEnergyTypeMaster({
  closeModal, activeEditId
}) {

  const { transportModeOptions, getDropdownListLoading  } =  useGetDropdownListOptions();
  const {
    values, formik, getEditEnergyTypeMasterStatus,
    getEditEnergyTypeMasterErrMsg
  } = useCreateEditEnergyTypeMaster(activeEditId);
  const {
    vehicleCatName, vehicleCatCode, transportModeId, activeStatus
  } = values;

  return (
    <>
      {
        getEditEnergyTypeMasterStatus === apiStatuses.pending && activeEditId
        && <Spinner />
      }

      {getEditEnergyTypeMasterStatus === apiStatuses.rejected && activeEditId && (
        <ErrorInPage message={getEditEnergyTypeMasterErrMsg} />
      )}

      {(getEditEnergyTypeMasterStatus === apiStatuses.succeeded || !activeEditId) &&
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <Grid container spacing={1.25}  style={{ paddingBottom: '225px' }}>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="vehicle-cat-name" size="small" fullWidth
                  value={vehicleCatName}
                  inputLabel="vehicle-cat-name"
                  formik={formik}
                  name="vehicleCatName"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="vehicle-cat-code" size="small" fullWidth
                  value={vehicleCatCode}
                  inputLabel="vehicle-cat-code"
                  formik={formik}
                  name="vehicleCatCode"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <Dropdown
                  options={transportModeOptions}
                  loading={getDropdownListLoading}
                  placeholder="transportmode-name"
                  inputLabel="transportmode-name"
                  value={transportModeId}
                  formik={formik}
                  name="transportModeId"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <SwitchInput
                  inputLabel="activeStatus"
                  leftLabel="inactive"
                  rightLabel="active"
                  value={activeStatus}
                  formik={formik}
                  name="activeStatus"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={closeModal} variant="outlined" sx={{ mr: 1 }}>
              <FormattedMessage id={"cancel"} />
            </Button>
            <AnimateButton>
              <Button type="submit" variant="contained" sx={{ mr: 1 }}>
                <FormattedMessage id={"save"} />
              </Button>
            </AnimateButton>
          </DialogActions>
        </form>
      }
    </>
  )
}


CreateEditEnergyTypeMaster.propTypes = {
  closeModal: PropTypes.func.isRequired,
  activeEditId: PropTypes.bool,
};

CreateEditEnergyTypeMaster.defaultProps = {
  activeEditId: 0,
};


export default CreateEditEnergyTypeMaster;