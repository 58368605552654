import useAuth from '@/from-theme/hooks/useAuth';
import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { clearData, fetchData } from '@/store/slices/fetch-slice';
import { getData, getStatus } from '@/store/slices/helpers/helpers';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetDivisionOptions() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);
  const { userData } = useAuth();
  const mapDivId = useMemo(() => userData?.data?.mapDivId || [], [userData]);

  const getDivisionData = getData(state, apiKeys.divisionMaster, []);
  const getDivisionStatus = getStatus(state, apiKeys.divisionMaster, '');
  const getDivisionLoading = apiStatuses.pending === getDivisionStatus;

  useEffect(() => {
    dispatch(
      fetchData({
        url: apiUrls.divisionMaster,
        method: apiMethods.GET,
        key: apiKeys.divisionMaster
      })
    );
  }, [dispatch]);

  const getDivisionOptions = useMemo(() => {
    return getDivisionData.filter((option) => option.activeStatus === "1")
    .map(({ divisionId, divisionName }) => ({
      id: divisionId,
      label: divisionName
    }))
  }, [getDivisionData]);

  const getDivisionFilterOptions = getDivisionData
  .filter((option) => option.activeStatus === "1" && mapDivId.includes(option.divisionId))
  .map(({ divisionId, divisionName }) => ({
    id: divisionId,
    label: divisionName
  }));

  const getFoodsDivisionOptions = getDivisionData
  .filter((option) => option.activeStatus === "1" && option.divisionId === 5)
  .map(({ divisionId, divisionName }) => ({
    id: divisionId,
    label: divisionName
  }));

  useEffect(
    () => () => {
      dispatch(clearData({ key: apiKeys.divisionMaster }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      getDivisionData,
      getFoodsDivisionOptions,
      getDivisionOptions,
      getDivisionFilterOptions,
      getDivisionStatus,
      getDivisionLoading
    }),
    [getDivisionData, getFoodsDivisionOptions,
       getDivisionOptions, getDivisionFilterOptions,
       getDivisionStatus, getDivisionLoading]
  );
}

export default useGetDivisionOptions;
