import { Alert, Button, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import useCreateEditMaterialMaster from './hooks/useCreateEditMaterialMaster';
import Dropdown from '@/common/components/dropdown/dropdown';
import { FormattedMessage } from 'react-intl';
import TextInput from '@/common/components/text-input/text-input';
import SwitchInput from '@/common/components/switch-input/switch-input';
import AnimateButton from '@/from-theme/components/@extended/AnimateButton';
import { apiStatuses } from '@/store/api/constants';
import Spinner from '@/common/components/spinner/spinner';
import ErrorInPage from '@/common/components/error-in-page/error-in-page';
import MainCard from '@/from-theme/components/MainCard';
import useGetBrandNameOptions from '@/common/hooks/useGetBrandMaster';
import useGetCategoryByBrand from '@/common/hooks/useGetCategoryByBrand';
import useGetProductByCategory from '@/common/hooks/useGetProductByCategory';
import { WarningFilled } from '@ant-design/icons';
import TextInputIcon from '@/common/components/text-inputIcon/text-input-icon';
import useGetDropdownListByDropdown from '@/common/hooks/useGetDropdownListByDropdown';

function CreateEditMaterialMaster({ activeEditId }) {
  const { getBrandNameOptions, getBrandNameLoading } = useGetBrandNameOptions();

  const {
    getCategoiesByBrandOptions: getCategoiesByBrandData,
    getCategoiesByBrand: getCategoiesByBrand,
    getCategoiesByBrandLoading: getCategoiesByBrandLoading
  } = useGetCategoryByBrand();
  const {
    getDropdownListByDropdownData: dropdownListData,
    getDropdownListByDropdown: getDropdownList,
    getDropdownListByDropdownLoading: dropdownListLoading
  } = useGetDropdownListByDropdown('dropdown');

  const {
    getProductByCategoryOptions: getProductNameOptions,
    getProductByCategory: getProductByCategory,
    getProductByCategoryLoading: getProductNameLoading
  } = useGetProductByCategory();

  const { values, formik, getEditMaterialMasterStatus, onSearch, getEditMaterialMasterErrMsg,
     getMaterialDataFromSapStatus, updateMaterialMasterStatus, onChangeCategory, alertVisible,
     onChangeBrand, handleSave, saveMaterialMasterStatus } = useCreateEditMaterialMaster({
      activeEditId,
      getCategoiesByBrand,
      getProductByCategory,
      getDropdownList
    });

  const isPending = [updateMaterialMasterStatus, saveMaterialMasterStatus]
  .includes(apiStatuses.pending);

  const {
    materialCode,
    materialName,
    buom,
    auom,
    conversionFactor,
    grossWeight,
    netWeight,
    controlCode,
    sku,
    deletionIndicator,
    brandId,
    categoryId,
    productId,
    activeStatus,
    materialTypeId
  } = values;

  return (
    <>
      {getEditMaterialMasterStatus === apiStatuses.pending && activeEditId && <Spinner />}
      {getEditMaterialMasterStatus === apiStatuses.rejected && activeEditId &&
       <ErrorInPage message={getEditMaterialMasterErrMsg} />}
      {(getEditMaterialMasterStatus === apiStatuses.succeeded || !activeEditId) && (
        <form onSubmit={formik.handleSubmit}>
          <MainCard>
          {alertVisible && Object.entries(formik.errors).map(([field, error]) => (
            <Alert
              key={field} 
              severity="error"
              variant="outlined"
              icon={<WarningFilled />} 
              sx={{ mb: 2 }} 
            >
              <Typography variant="body1">{`${field}: ${error}`}</Typography>
            </Alert>
          ))}
            <Grid container spacing={2} style={{ paddingBottom: '225px' }}>
            <Grid item xs={12} sm={12} md={4} lg={3}>
                <TextInputIcon
                  placeholder="material-code"
                  size="small"
                  fullWidth
                  value={materialCode || ''}
                  inputLabel="material-code"
                  formik={formik}
                  name="materialCode"
                  onSearchIconClick={!activeEditId ? onSearch : undefined}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && !activeEditId) {
                      onSearch();
                    }
                  }}
                />
              </Grid>
             
              {getEditMaterialMasterStatus === apiStatuses.pending && <Spinner />}
              { (getMaterialDataFromSapStatus === apiStatuses.succeeded || activeEditId !== 0 ) && (
                  <>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <TextInput
                        placeholder="material-name"
                        size="small"
                        fullWidth
                        value={materialName}
                        inputLabel="material-name"
                        formik={formik}
                        name="materialName"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <TextInput placeholder="buom" size="small" fullWidth value={buom}
                       inputLabel="buom" formik={formik} name="buom" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <TextInput placeholder="auom" size="small" fullWidth value={auom}
                       inputLabel="auom" formik={formik} name="auom" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <TextInput
                        placeholder="conversion-factor"
                        size="small"
                        fullWidth
                        value={conversionFactor}
                        inputLabel="conversion-factor"
                        formik={formik}
                        name="Conversion Factor"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <TextInput
                        placeholder="gross-weight"
                        size="small"
                        fullWidth
                        value={grossWeight}
                        inputLabel="gross-weight"
                        formik={formik}
                        name="grossWeight"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <TextInput
                        placeholder="net-weight"
                        size="small"
                        fullWidth
                        value={netWeight}
                        inputLabel="net-weight"
                        formik={formik}
                        name="netWeight"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <TextInput
                        placeholder="control-code"
                        size="small"
                        fullWidth
                        value={controlCode}
                        inputLabel="control-code"
                        formik={formik}
                        name="controlCode"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <TextInput placeholder="sku" size="small" fullWidth value={sku} 
                      inputLabel="sku" formik={formik} name="sku" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <TextInput
                        placeholder="deletion-indicator"
                        size="small"
                        fullWidth
                        value={deletionIndicator}
                        inputLabel="deletion-indicator"
                        formik={formik}
                        name="deletionIndicator"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <Dropdown
                        options={dropdownListData}
                        loading={dropdownListLoading}
                        placeholder="material-type"
                        inputLabel="material-type"
                        value={materialTypeId}
                        formik={formik}
                        name="materialTypeId"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <Dropdown
                        options={getBrandNameOptions}
                        loading={getBrandNameLoading}
                        placeholder="brand-name"
                        inputLabel="brand-name"
                        value={brandId}
                        formik={formik}
                        name="brandId"
                        onChange={onChangeBrand}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <Dropdown
                        options={getCategoiesByBrandData}
                        loading={getCategoiesByBrandLoading}
                        placeholder="category-name"
                        inputLabel="category-name"
                        value={categoryId}
                        formik={formik}
                        name="categoryId"
                        onChange={onChangeCategory}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      <Dropdown
                        options={getProductNameOptions}
                        loading={getProductNameLoading}
                        placeholder="product-name"
                        inputLabel="product-name"
                        value={productId}
                        formik={formik}
                        name="productId"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <SwitchInput
                        inputLabel="activeStatus"
                        leftLabel="inactive"
                        rightLabel="active"
                        value={activeStatus} 
                        formik={formik}
                        name="activeStatus"
                      />
                    </Grid>
             
                    <Grid item xs={12}>
                      <Stack direction="row" className='btn-end' >
                        <AnimateButton>
                          <Button type="submit" variant="contained" onClick={handleSave}
                           disabled={isPending}>
                            <FormattedMessage id="save" />
                          </Button>
                        </AnimateButton>
                      </Stack>
                    </Grid>
                  </>
                )}
            </Grid>
          </MainCard>
        </form>
      )}
    </>
  );
}

CreateEditMaterialMaster.propTypes = {
  closeModal: PropTypes.func.isRequired,
  activeEditId: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

CreateEditMaterialMaster.defaultProps = {
  activeEditId: 0
};

export default CreateEditMaterialMaster;
