import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getData, getStatus, getErrorMessage } from '@/store/slices/helpers/helpers';
import { clearData, fetchData } from '@/store/slices/fetch-slice';
import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { initialValues } from '../constants/constants';
import { getFormattedPayload } from '@/common/helpers/helpers';

export default function usePrimaryCustomer({getDistrict, getCity}) {
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [activeEditId, setActiveEditId] = useState(0);
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const savePrimaryCustomerMasterStatus = getStatus(state, apiKeys.savePrimaryCustomerMaster, '');
  const updatePrimaryCustomerMasterStatus = getStatus(state, 
    apiKeys.updatePrimaryCustomerMaster, '');
  const primaryCustomerMasterData = getData(state, apiKeys.primaryCustomer, {});
  const primaryCustomerMasterStatus = getStatus(state, apiKeys.primaryCustomer, '');
  const primaryCustomerMasterErrMsg = getErrorMessage(state, apiKeys.primaryCustomer, '');
  const deletePrimaryCustomerStatus = getStatus(state, apiKeys.deletePrimaryCustomer, '');

  const validationSchema = yup.object({
    stateId: yup.array().nullable(),
    districtId: yup.array().nullable(),
    cityId: yup.array().nullable()
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      getPrimaryCustomer();
    }
  });

  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;
  const { values } = formik;
  const { stateId, districtId } = values;

  const getPrimaryCustomer = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.primaryCustomer,
        method: apiMethods.GET,
        key: apiKeys.primaryCustomer,
        failureMsg: formatMessage({ id: 'failed-to-get-primary-customer-master' })
      })
    );
  }, [dispatch, formatMessage]);

  useEffect(() => {
    getPrimaryCustomer();
    if (stateId) {
      getDistrict(stateId?.id);
    }
    if (districtId ) {
      let payload = 0;
      payload = getFormattedPayload({ districtId });
      getCity(payload);
    }
  }, [dispatch, getPrimaryCustomer, getDistrict, getCity, stateId, districtId]);

  useEffect(() => {
    if (deletePrimaryCustomerStatus === apiStatuses.succeeded) {
      getPrimaryCustomer();
    }
  }, [deletePrimaryCustomerStatus, getPrimaryCustomer]);

  useEffect(
    () => () => {
      dispatch(clearData({ key: apiKeys.primaryCustomer }));
    },
    [dispatch]
  );

  const onRowEdit = useCallback((id) => {
    setIsCreateEditModalOpen(true);
    setActiveEditId(id);
  }, []);
  const closeModal = useCallback(() => {
    setIsCreateEditModalOpen(false);
    setActiveEditId(0);
  }, []);

  useEffect(() => {
    if (savePrimaryCustomerMasterStatus === apiStatuses.succeeded) {
      closeModal();
      getPrimaryCustomer();
    }
  }, [savePrimaryCustomerMasterStatus, closeModal, getPrimaryCustomer]);

  useEffect(() => {
    if (updatePrimaryCustomerMasterStatus === apiStatuses.succeeded) {
      closeModal();
      getPrimaryCustomer();
    }
  }, [updatePrimaryCustomerMasterStatus, closeModal, getPrimaryCustomer]);

  return useMemo(
    () => ({
      formik,
      values: formik.values,
      getPrimaryCustomer,
      primaryCustomerMasterData,
      primaryCustomerMasterStatus,
      primaryCustomerMasterErrMsg,
      isCreateEditModalOpen,
      setIsCreateEditModalOpen,
      onRowEdit,
      activeEditId,
      closeModal
    }),
    [
      formik,
      getPrimaryCustomer,
      primaryCustomerMasterData,
      primaryCustomerMasterStatus,
      primaryCustomerMasterErrMsg,
      isCreateEditModalOpen,
      setIsCreateEditModalOpen,
      onRowEdit,
      activeEditId,
      closeModal
    ]
  );
}
