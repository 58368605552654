import { useCallback, useEffect, useMemo } from "react";
import { useIntl } from 'react-intl';
import { useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getData, getStatus, getErrorMessage } from "@/store/slices/helpers/helpers";
import { clearMultipleData, fetchData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiUrls } from "@/store/api/constants";
import { useState } from "react";
import useAuth from "@/from-theme/hooks/useAuth";
import navs from '@/from-theme/routes/constants/nav';
import { useNavigate } from "react-router";

export default function useNotification() {
  const {formatMessage} = useIntl();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(1);
  const dispatch = useDispatch();
  const state = useSelector((store) => store);
  const { userData } = useAuth();
  const designation = userData?.data?.designationId;
  const navigate = useNavigate();

  const notificationData  = getData(state, apiKeys.getWebNotificationCount, []);
  const notificationStatus = getStatus(state, apiKeys.getWebNotificationCount, '');
  const notificationErrMsg = getErrorMessage(state, apiKeys.getWebNotificationCount, '');

  const getNotification = useCallback(() => {
    dispatch(fetchData({
      url: apiUrls.getWebNotificationCount,
      method: apiMethods.POST,
      key: apiKeys.getWebNotificationCount,
      failureMsg: formatMessage({id: "failed-to-get-notification-count"}),
      body: ({designationId:designation})
    }))
  }, [dispatch, formatMessage, designation])

  useEffect(() => {
    let intervalId;
    const eligibleDesignations = [ 406, 414, 415, 594, 421];
    if (eligibleDesignations.includes(designation)) {
      getNotification();
      intervalId = setInterval(getNotification, 300000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [designation, getNotification]);

  useEffect(() => {
    if (notificationData) {
      const totalCount = 
        (notificationData.planApprovalCount || 0) + 
        (notificationData.planDeviationCount || 0) + 
        (notificationData.claimCount || 0);
      setCount(totalCount);
      const audio = new Audio('/assets/audio/notification.wav');
      const playAudio = async () => {
        try {
          await audio.play();
        } catch (error) {
          console.error('Error playing audio:', error);
        }
      };
      playAudio();
      return () => {
        audio.pause();
        audio.currentTime = 0; // Reset audio to start
      };
    }
  }, [notificationData]);


  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handlePlanApprovalIfoods = () => {
    navigate(navs.bOPlanApproval);
  };
  const handlePlanApprovalfoods = () => {
    navigate(navs.PlanApprovalFoods);
  };
  const handleClaimApproval = () => {
    navigate(navs.ClaimApprovalBOIF);
  };

  useEffect(() => ()=> {
    dispatch(clearMultipleData({key: apiKeys.divisionMaster}));
  }, [dispatch]);
    return useMemo(() => ({
      handleClose,
      handleToggle,
      count,
      setCount,
      open,
      setOpen,
      anchorRef,
      getNotification,
      notificationData,
      notificationStatus,
      notificationErrMsg,
      handlePlanApprovalIfoods,
      handlePlanApprovalfoods,
      handleClaimApproval
    }),[
      handleClose,
      handleToggle,
      count,
      setCount,
      open,
      setOpen,
      anchorRef,
      getNotification,
      notificationData,
      notificationStatus,
      notificationErrMsg,
      handlePlanApprovalIfoods,
      handlePlanApprovalfoods,
      handleClaimApproval
    ])
}
