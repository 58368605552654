import React from "react";
import TableView from "@/common/components/TableView/TableView";
import { Grid, Button } from "@mui/material";
import { PlusOutlined } from "@ant-design/icons";
import { columns, reportFileName } from "./constants/constants";
import useCountryMaster from "./hooks/useCountryMaster";
import CreateEditCountryMaster from "./create-country-master/create-country-master";
import ModalDialog from "@/common/components/modal-dialog/modal-dialog";
import { FormattedMessage } from "react-intl";
import useOnActions from "./hooks/useOnActions";
import AlertDialog from "@/common/components/alert-dialog/alert-dialog";
import VerifyAccess from "@/common/components/verify-access/verify-access";
import { requestedMenuAll, requestedFeaturesAll } from "@/from-theme/routes/constants/menuAccess";
import { isUserHasAccess } from "@/from-theme/utils/route-guard/helpers/helpers";
import useAuth from "@/from-theme/hooks/useAuth";

const {countryTabMasterKey} = requestedMenuAll;
const {createKey, updateKey, deleteKey} = requestedFeaturesAll;

export default function Country() {
    const { countryMasterData, isCreateEditModalOpen, setIsCreateEditModalOpen, onRowEdit,
        activeEditId, closeModal, countryMasterStatus, countryMasterErrMsg } = useCountryMaster();
    const {
        onRowDelete,
        deleteCountryMasterStatus,
        isAlertDialog,
        onCloseAlert,
        onAgreeAlert
    } = useOnActions();
    const { userData } = useAuth();
        const entitlement = userData?.entitlement || {};

    const isUpdateDeleteAccess = 
    isUserHasAccess(countryTabMasterKey, updateKey, entitlement) || 
    isUserHasAccess(countryTabMasterKey, deleteKey, entitlement); 
  
    const actionButton = (
        <VerifyAccess requestedMenu={countryTabMasterKey} requestedFeature={createKey}>
            <Button
                variant="contained"
                startIcon={<PlusOutlined />}
                onClick={() => setIsCreateEditModalOpen(true)}
                ><FormattedMessage id="add-country" />
            </Button>
        </VerifyAccess>
        );

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TableView
                    data={countryMasterData}
                    columns={columns({ onRowEdit, onRowDelete, deleteCountryMasterStatus,
                        isUpdateDeleteAccess
                     })}
                    actionButton={actionButton}
                    reportFileName={reportFileName}
                    apiStatus={countryMasterStatus}
                    apiErrorMsg={countryMasterErrMsg}
                    stringInExport={["activeStatus"]}
                />
                {isCreateEditModalOpen && (
                    <ModalDialog
                        closeModal={() => closeModal()}
                        modalTitle={!activeEditId ? "create-country-master" :
                         "edit-country-master"}
                         
                    >
                        <CreateEditCountryMaster closeModal={() => closeModal()}
                            activeEditId={activeEditId} />
                    </ModalDialog>
                )}
                {isAlertDialog && (
                    <AlertDialog onCloseAlert={onCloseAlert} onAgreeAlert={onAgreeAlert} />
                )}
            </Grid>
        </Grid>
    );
}
