import React from "react";
import TableView from "@/common/components/TableView/TableView";
import { Grid, Button } from "@mui/material";
import { PlusOutlined } from "@ant-design/icons";
import { columns, reportFileName } from "./constants/constants";
import useMenuAccess from "./hooks/useMenuAccess";
import { FormattedMessage } from "react-intl";
import { useNavigate } from 'react-router-dom';
import navs from "@/from-theme/routes/constants/nav";
import useOnActions from "./hooks/useOnActions";
import AlertDialog from "@/common/components/alert-dialog/alert-dialog";
import VerifyAccess from "@/common/components/verify-access/verify-access";
import { requestedMenuAll, requestedFeaturesAll } from "@/from-theme/routes/constants/menuAccess";
import { isUserHasAccess } from "@/from-theme/utils/route-guard/helpers/helpers";
import useAuth from "@/from-theme/hooks/useAuth";

const {menuAccessTabMasterKey} = requestedMenuAll;
const {createKey, updateKey, deleteKey} = requestedFeaturesAll;
export default function Menuaccess() {
    const navigate = useNavigate();
    const { menuAccessData, menuAccessStatus, menuAccessErrMsg } = useMenuAccess();
    const { userData } = useAuth();
    const entitlement = userData?.entitlement || {};
  
    const isUpdateDeleteAccess = isUserHasAccess(menuAccessTabMasterKey, updateKey, entitlement)
      || isUserHasAccess(menuAccessTabMasterKey, deleteKey, entitlement);       
    const {
        onRowEdit,
        onRowDelete,
        deleteMenuAccessStatus,
        isAlertDialog,
        onCloseAlert,
        onAgreeAlert
    } = useOnActions();

    const actionButton = (
    <VerifyAccess requestedMenu={menuAccessTabMasterKey} requestedFeature={createKey}>
        <Button
            variant="contained"
            startIcon={<PlusOutlined />}
            onClick={() => navigate(navs.menuAccessCreate)}
        ><FormattedMessage id="add-menu-access" />
        </Button>
        </VerifyAccess>
    );

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TableView
                    data={menuAccessData}
                    columns={columns({ onRowEdit, onRowDelete, deleteMenuAccessStatus,
                        isUpdateDeleteAccess })}
                    actionButton={actionButton}
                    reportFileName={reportFileName}
                    apiStatus={menuAccessStatus}
                    apiErrorMsg={menuAccessErrMsg}
                    stringInExport={["activeStatus"]}
                />
                {isAlertDialog && (
                    <AlertDialog onCloseAlert={onCloseAlert} onAgreeAlert={onAgreeAlert} />
                )}
            </Grid>
        </Grid>
    );
}
